<template>
    <div class="" >
      <BaseLoading
        style="min-height: 400px; height: 90vh"
        v-if="loading"
      ></BaseLoading>
  
      <div class="p-sm-4  p-1" v-else>
  
        <div class=" is-order-card mt-2 ">
          <div class="  " style="border-radius: 20px">
            <div class="row" >
              <div v-for="(item, index) in notification" :key="index + 'notifications'" class="col-12 col-sm-4 col-md-4 text-left" sm="6">
                <!-- {{ item }} -->
                <h1
                class="order-id"
                >
                  News ID # {{ item.id || "" }}
                </h1>
                <p >
<!--                   
                  <strong>{{ classHelpOrder.no_weeks || "" }}Week(s)</strong> |
                  <strong>{{ classHelpOrder.no_pages || "" }}Page(s) </strong> | -->
                  <span
                  class="
                  
                    mx-sm-2 mx-1 my-sm-0 my-2
                  "
                  >Status: 
                    <v-chip v-if="item.is_read == 1" class="ma-2" color="primary" small
                        >Read</v-chip
                    >
                    <v-chip v-else class="ma-2" color="error"  small
                        >Unread</v-chip
                    >
                    </span
                >
                </p>
                
              </div>
  
              <div class="col-12 col-sm-8 col-md-8" sm="6">
                
              </div>
            </div>
  
            <ul class="nav nav-tabs d-flex align-items-center justify-content-center w-100 text-center nav-tabs-bottom pl-0">
              <li class="nav-item">
                <a
                  href="#info"
                  class="nav-link px-sm-4 active show font-weight-bold text-uppercase"
                  data-toggle="tab"
                  >News Info</a
                >
              </li>
             
            </ul>
  
            <div v-for="(item, index) in notification" :key="index + 'notifications'" class="tab-content  orderlisting--card card p-4 my-3">
              <div class="tab-pane fade active show" id="info">
                <div  class="order-info text-left">
                    <div class="row">
                    <div class="col-12 col-sm-9 table-responsive">
                        <div class="order-details-item my-2 order-details-item-paper-detailes">
                        <div class="order-details-header d-flex justify-content-between">
                            <div class="title py-2 ">News Details</div>
                        </div>                                     
                        
                        </div>
                        <table
                        class="table table-borderless table--custom text-left "
                        style="font-size: 13px !important"
                        >
                        <!-- <tr>
                            <td>Date sent</td>
                            <td>{{ item.create_stamp || "" }}</td>
                        </tr> -->



                        <tr>
                            <td>Sent by</td>
                            <td>
                            {{ item.sender || "" }} 
                            </td>
                        </tr>

            
                        <tr>
                            <td>Subject</td>
                            <td>
                            {{ item.subject || "" }} 
                            </td>
                        </tr>
                       


                        
                        </table>
            
                        <div class="order-details-item my-4 order-details-item-paper-detailes">
                        <div class="order-details-header d-flex justify-content-between">
                            <div class="title py-2 ">News Message</div>
                        </div>                                     
                        <div class="order-details-block">
                            <div class="order-option-value order-option-instructions" data-full-text="In ducimus mollitia In ducimus mollitia In ducimus mollitia <span class='see-less-instructions'>See less</span>" data-truncate-text="In ducimus mollitia In ducimus mollitia In ducimus mollitia"> 
                                <pre style="padding: 4px !important">                 
                                <span class="class_desc" v-html="item.message"></span>
                                </pre>
                            </div>
                        </div>
                        </div>
                        
                    </div>

                    <!-- <div class="col-12 my-2 col-sm-3">
                      james
                    </div> -->
            
            
                    
            
                    
                    </div>
            
                   
                </div>
  
  
  
                
              </div>
  
              
            </div>
          </div>
        </div>
  
       
  
        
  
      </div>
  
      <!-- <BaseModal :title="'Wallet Topup'" :modalId="'walletModal'">
        <WalletTopup></WalletTopup>
      </BaseModal>
  
      <BaseModal :title="'Wallet Topup'" :modalId="'tipModal'">
        <TipWriter></TipWriter>
      </BaseModal> -->
    </div>
  </template>
  
  <script>
  import WalletTopup from "../../components/payments/modals/WalletTopup.vue";
  import TipWriter from "../../components/payments/modals/TipWriter.vue";
  import OrderInfo from "../../components/order/OrderInfo.vue";
  import OrdersubInfo from "../../components/order/OrdersubInfo.vue";
  import WriterOrderInfo from "../../components/order/WriterOrderInfo.vue";
  import EditorOrderInfo from "../../components/order/EditorOrderInfo.vue";
  import OrderFiles from "../../components/order/OrderFiles.vue";
  import OrderMessages from "../../components/order/OrderMessages.vue";
  import UpdateOrderStatus from "../../components/order/modals/UpdateOrderStatus.vue";
  import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
  import FineComponent from "../../components/payments/FineComponent.vue";
  import DueCounter from "../../components/order/DueCounter.vue";
  import http from "../../modules/http.index";
  
  // import Rating from "../../components/order/Rating.vue";
  export default {
    name: "ViewClass",
    components: {
      OrderInfo,
      WriterOrderInfo,
      EditorOrderInfo,
      OrderFiles,
      OrderMessages,
      // Rating,
      UpdateOrderStatus,
      WalletTopup,
      TipWriter,
      FineComponent,
      DueCounter,
      OrdersubInfo
    },
    computed: {
    //   ...mapState("order", ["order"]),
      ...mapGetters("auth", ["userType", "userTypes"]),
      ...mapState("auth", ["user"]),
      ...mapState("order",  ["order", 'classHelpOrder']),
      ...mapState("news", ["notification"]),

    },
    data: () => ({
      order_action: {},
      deadline: false,
      writers:[],
      loading:false
    }),
    async mounted() {
      this.loading = true;
    //   await this._getOrder(this.$route.params.id).catch((err) => {
    //     console.log("err.response", err.response);
    //     if (err && err.response && err.response.status == 404) {
    //       this.$router.push({ name: "Home404" });
    //     }
    //   });
    await this._getNot(this.$route.params.id)
      .then(() => (this.$store.state.loading = false))
      .catch(() => {
        this.$router.push({ name: "404" });
      });
      
      this.loading = false;
    },
    methods: {
    //   ...mapActions("order", ["_getOrder"]),
      ...mapActions("setups", ["getSetups"]),
      ...mapActions("messages", ["_getMessages"]),
      ...mapMutations("order", ["unsetOrder", "changeStatus"]),
      ...mapActions("order", ["_getOrder", "_getClassHelpOrder"]),
      ...mapActions("news", ["_getNot"]),


      
    },
 
  };
  </script>
  
  <style scoped lang="scss">
  .nav-tabs {
    margin-bottom: 0px !important;
  }
  .order-title {
    max-height: 32px;
    overflow: hidden;
  }
  
  @media screen and (min-width: 768px) {
    .is-order-card {
      min-height: 74vh;
    }
  }
  
  .is-animated {
    transition: 0.3s ease-in-out;
  }
  .nav-tabs a.nav-link {
    padding: 5px 9px;
  }
  </style>